import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { User as FirebaseUser } from '@angular/fire/auth';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Observable, map } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { ListHeaderComponent } from '../../../components/list/list-header/list-header.component';
import { ListItemComponent } from '../../../components/list/list-item/list-item.component';
import { ListComponent } from '../../../components/list/list/list.component';
import { LoginButtonComponent } from '../../../components/login-button/login-button.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { SlashkitConfig } from '../../../models/config';
import { User } from '../../../models/user';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { PhoneNumberPipe } from '../../../pipes/phone-number.pipe';
import { AuthModalService } from '../../../services/auth/auth-modal.service';
import { OAuthService } from '../../../services/auth/oauth.service';
import { PasswordAuthService } from '../../../services/auth/password-auth.service';
import { PhoneAuthModalService } from '../../../services/auth/phone-auth-modal.service';
import { SessionService } from '../../../services/auth/session.service';
import { UserDetailService } from '../../../services/user-detail.service';
import { UserService } from '../../../services/user.service';
import { combineStream } from '../../../util';

export interface MypageAccountState {
  firebaseUser: FirebaseUser;
  hasEmailProvider: boolean;
  user: User;
}

@Component({
  selector: 'page-mypage-account',
  templateUrl: './mypage-account.page.html',
  styleUrls: ['./mypage-account.page.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    LoadingDirective,
    ListComponent,
    ListHeaderComponent,
    ListItemComponent,
    ButtonComponent,
    NgClass,
    MatProgressSpinner,
    NgTemplateOutlet,
    IconComponent,
    LoginButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalDatePipe,
    LocalePipe,
    PhoneNumberPipe,
  ],
})
export class MypageAccountPage implements OnInit {
  state$: Observable<MypageAccountState>;
  loading = true;
  sendVerificationEmailStatus: 'unsent' | 'sending' | 'sent' = 'unsent';

  readonly oauthService = inject(OAuthService);

  private readonly slashkitConfig = inject(SlashkitConfig);
  private readonly sessionService = inject(SessionService);
  private readonly userService = inject(UserService);
  private readonly userDetailService = inject(UserDetailService);
  private readonly phoneAuthModalService = inject(PhoneAuthModalService);
  private readonly passwordAuthService = inject(PasswordAuthService);
  private readonly authModalService = inject(AuthModalService);

  get enablePhoneSignIn() {
    return this.slashkitConfig.authProviders.signIn.phone || this.slashkitConfig.authProviders.signUp.phone;
  }
  get enableEmailSignIn() {
    return this.slashkitConfig.authProviders.signIn.email || this.slashkitConfig.authProviders.signUp.email;
  }
  get enableGoogleSignIn() {
    return this.slashkitConfig.authProviders.signIn.google || this.slashkitConfig.authProviders.signUp.google;
  }
  get enableAppleSignIn() {
    return this.slashkitConfig.authProviders.signIn.apple || this.slashkitConfig.authProviders.signUp.apple;
  }
  get enableThirdPartySignIn() {
    return this.enableGoogleSignIn || this.enableAppleSignIn;
  }
  get enableNewsletter() {
    return this.slashkitConfig.enableNewsletter ?? false;
  }

  constructor() {
    this.state$ = combineStream({
      firebaseUser: this.sessionService.firebaseUser$,
      hasEmailProvider: this.sessionService.firebaseUser$.pipe(
        map((user) => this.passwordAuthService.hasEmailProvider(user)),
      ),
      user: this.userService.currentUser$,
    });
  }

  ngOnInit() {
    this.userService.fetchCurrentUser().subscribe(() => {
      this.loading = false;
    });
  }

  showDateOfBirthModal() {
    this.userDetailService.showDateOfBirthModal();
  }

  updatePhoneNumber() {
    this.phoneAuthModalService.showPhoneAuthModal({ actionType: 'update' });
  }

  registerEmail() {
    this.authModalService.showEmailLinkModal({ actionType: 'register' });
  }

  subscribeNewsletter() {
    this.userService.subscribeNewsletter();
  }

  unsubscribeNewsletter() {
    this.userService.unsubscribeNewsletter();
  }

  changeEmail() {
    this.authModalService.showEmailLinkModal({ actionType: 'update' });
  }

  linkWithGoogle() {
    this.oauthService.linkWithGoogle({ showConfirmation: true, autoSignIn: false });
  }

  linkWithApple() {
    this.oauthService.linkWithApple({ showConfirmation: true, autoSignIn: false });
  }

  unlink(providerId: Parameters<OAuthService['unlink']>[0]) {
    this.oauthService.unlink(providerId);
  }

  async resendVerificationEmail() {
    this.sendVerificationEmailStatus = 'sending';
    await this.passwordAuthService.sendVerificationEmail().catch((error) => {
      this.sendVerificationEmailStatus = 'unsent';
      return null;
    });
    // unsent の場合は失敗してるので、ここでは何もしない
    if (this.sendVerificationEmailStatus === 'sending') {
      this.sendVerificationEmailStatus = 'sent';
    }
  }
}
