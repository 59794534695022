import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isTrueProperty } from '../../../util';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'sl-list-item, a[sl-list-item]',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class ListItemComponent {
  _hasArrow = false;

  @Input('has-arrow')
  set hasArrow(value: any) {
    this._hasArrow = isTrueProperty(value);
  }

  @Input() icon: string;

  constructor() {}

  type(): 'icon' | 'arrow' | 'default' {
    if (this.icon) {
      return 'icon';
    }
    if (this._hasArrow) {
      return 'arrow';
    }
    return 'default';
  }
}
