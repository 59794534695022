<sl-page-header>{{ 'shared.breadcrumbs.mypage' | locale }}</sl-page-header>

<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <sl-layout size="small" class="content">
      @if (!state?.firebaseUser?.isAnonymous) {
        <sl-list>
          <sl-list-header>{{ 'pages.mypageRoot.account' | locale }}</sl-list-header>
          <ng-container>
            <a sl-list-item has-arrow routerLink="payment">
              <div class="menu">
                <sl-icon class="menu-icon" name="payment"></sl-icon>
                <div class="menu-text notice">
                  <div>{{ 'pages.mypageRoot.paymentSettings' | locale }}</div>
                  @if (!state?.card) {
                    <div class="notice-badge">
                      <sl-icon class="icon" name="notice"></sl-icon>
                      <div class="text">{{ 'pages.mypageRoot.notSet' | locale }}</div>
                    </div>
                  }
                </div>
              </div>
            </a>
            <a sl-list-item has-arrow routerLink="account">
              <div class="menu">
                <sl-icon class="menu-icon" name="account"></sl-icon>
                <div class="menu-text notice">
                  <div>{{ 'pages.mypageRoot.accountSettings' | locale }}</div>
                  @if (!state?.user?.dateOfBirth) {
                    <div class="notice-badge">
                      <sl-icon class="icon" name="notice"></sl-icon>
                      <div class="text">{{ 'pages.mypageRoot.notSet' | locale }}</div>
                    </div>
                  }
                </div>
              </div>
            </a>
            <a sl-list-item has-arrow routerLink="coupon">
              <div class="menu">
                <sl-icon class="menu-icon" name="coupon"></sl-icon>
                <div class="menu-text">{{ 'pages.mypageRoot.coupon' | locale }}</div>
              </div>
            </a>
            <a sl-list-item has-arrow routerLink="history">
              <div class="menu">
                <sl-icon class="menu-icon" name="history"></sl-icon>
                <div class="menu-text">{{ 'pages.mypageRoot.purchaseHistory' | locale }}</div>
              </div>
            </a>
          </ng-container>
        </sl-list>
      }
      @if (state?.firebaseUser?.isAnonymous) {
        <sl-login-buttons></sl-login-buttons>
      }
      <sl-list>
        <sl-list-header>{{ 'pages.mypageRoot.otherLinks' | locale }}</sl-list-header>
        @for (link of navLinks; track link.href) {
          <a sl-list-item has-arrow [slLink]="link">{{ link?.label }}</a>
        }
      </sl-list>
      @if (!state?.firebaseUser?.isAnonymous) {
        <div class="logout">
          <a class="logout-link" (click)="signOut()">{{ 'shared.account.signOut' | locale }}</a>
        </div>
      }
    </sl-layout>
  }
</ng-container>
