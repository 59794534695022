<sl-breadcrumb>
  <sl-breadcrumb-item path="/mypage">{{ 'shared.breadcrumbs.mypage' | locale }}</sl-breadcrumb-item>
  <sl-breadcrumb-item>{{ 'shared.breadcrumbs.purchaseHistory' | locale }}</sl-breadcrumb-item>
</sl-breadcrumb>

<sl-page-header>{{ 'shared.breadcrumbs.purchaseHistory' | locale }}</sl-page-header>

<ng-container *slLoading="!loading">
  @if (checkouts$ | async; as checkouts) {
    @if (checkouts?.length) {
      <sl-layout size="small" class="content" infiniteScroll (scrolled)="onScroll()">
        <div class="checkouts">
          @for (checkout of checkouts; track checkout.id) {
            <sl-lottery-checkout
              class="checkouts-item"
              [checkout]="checkout"
              routerLink="{{ checkout?.id }}"
            ></sl-lottery-checkout>
          }
        </div>
      </sl-layout>
    } @else {
      <sl-empty>{{ 'pages.mypageHistory.emptyMessage' | locale }}</sl-empty>
    }
  }
</ng-container>
