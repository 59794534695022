import { inject } from '@angular/core';
import { Auth as FireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SessionService } from '../services/auth/session.service';

export const loginGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<UrlTree | boolean> => {
  const sessionService = inject(SessionService);
  const auth = inject(FireAuth);
  const router = inject(Router);

  const user = auth.currentUser;
  if (user?.isAnonymous === false) {
    return true;
  }
  const status = await sessionService.signIn();
  if (status) {
    return true;
  } else {
    return router.parseUrl('/mypage');
  }
};
