<ng-container *slLoading="!loading">
  @if (coupons$ | async; as coupons) {
    @if (coupons?.length) {
      <sl-layout size="small" class="content" infiniteScroll (scrolled)="onScroll()">
        <div class="coupons">
          @for (coupon of coupons; track coupon.id) {
            <sl-coupon-item [coupon]="coupon" (click)="showCouponItemModal(coupon?.id)" expired></sl-coupon-item>
          }
        </div>
      </sl-layout>
    } @else {
      <sl-empty>{{ 'pages.mypageCouponExpired.emptyMessage' | locale }}</sl-empty>
    }
  }
</ng-container>
