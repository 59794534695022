import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { LotteryCoupon, LotteryCouponStatus } from '../../../models/lottery-coupon';
import { CouponKindPipe } from '../../../pipes/coupon-kind.pipe';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { CouponCodeService } from '../../../services/coupon-code.service';
import { isTrueProperty } from '../../../util';

@Component({
  selector: 'sl-coupon-item',
  templateUrl: './coupon-item.component.html',
  styleUrls: ['./coupon-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CouponKindPipe, LocalDatePipe, LocalePipe],
})
export class CouponItemComponent implements OnInit {
  @Input() coupon: LotteryCoupon;
  _expired = false;
  @Input()
  set expired(value: any) {
    this._expired = isTrueProperty(value);
  }

  private couponCodeService = inject(CouponCodeService);

  ngOnInit() {}

  getCouponStatus(coupon: LotteryCoupon): LotteryCouponStatus {
    return this.couponCodeService.getCouponStatus(coupon);
  }
}
