import { Routes } from '@angular/router';

import { loginGuard } from '../../guards/login.guard';
import { TabsPageGuard } from '../../guards/tabs-page.guard';

import { MypageAccountPage } from './mypage-account/mypage-account.page';
import { MypageCouponConsumablePage } from './mypage-coupon-consumable/mypage-coupon-consumable.page';
import { MypageCouponExpiredPage } from './mypage-coupon-expired/mypage-coupon-expired.page';
import { MypageCouponNonConsumablePage } from './mypage-coupon-non-consumable/mypage-coupon-non-consumable.page';
import { MypageCouponPage } from './mypage-coupon/mypage-coupon.page';
import { MypageHistoryDetailPage } from './mypage-history-detail/mypage-history-detail.page';
import { MypageHistoryPage } from './mypage-history/mypage-history.page';
import { MypagePaymentPage } from './mypage-payment/mypage-payment.page';
import { MypageRootPage } from './mypage-root/mypage-root.page';

const mypageRoutes: Routes = [
  {
    path: '',
    canActivate: [TabsPageGuard],
    canDeactivate: [TabsPageGuard],
    children: [
      {
        path: '',
        component: MypageRootPage,
      },
      {
        path: 'account',
        canActivate: [loginGuard],
        component: MypageAccountPage,
      },
      {
        path: 'payment',
        canActivate: [loginGuard],
        component: MypagePaymentPage,
      },
      {
        path: 'coupon',
        canActivate: [loginGuard],
        component: MypageCouponPage,
        children: [
          {
            path: 'consumable',
            component: MypageCouponConsumablePage,
          },
          {
            path: 'non-consumable',
            component: MypageCouponNonConsumablePage,
          },
          {
            path: 'expired',
            component: MypageCouponExpiredPage,
          },
          {
            path: '',
            redirectTo: '/mypage/coupon/consumable',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'history',
        canActivate: [loginGuard],
        children: [
          {
            path: '',
            component: MypageHistoryPage,
          },
          {
            path: ':checkoutId',
            component: MypageHistoryDetailPage,
          },
        ],
      },
    ],
  },
];

export default mypageRoutes;
