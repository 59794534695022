@switch (card.brand) {
  @case ('American Express') {
    <div class="placeholder">**** ****** *</div>
  }
  @case ('Diners Club') {
    <div class="placeholder">**** ******&nbsp;</div>
  }
  @default {
    <div class="placeholder">**** **** ****&nbsp;</div>
  }
}
<div class="last4">{{ card.last4 }}</div>
