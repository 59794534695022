<div class="coupon" [class.expired]="_expired">
  <div class="coupon-kind">{{ coupon?.kind | couponKind }}</div>
  <div class="coupon-lottery">{{ coupon?.machine?.name }}</div>
  <div class="coupon-name">{{ coupon?.name }}</div>
  @if (getCouponStatus(coupon); as status) {
    @switch (status) {
      @case ('valid') {
        <div class="coupon-date">
          {{ 'components.couponItem.expiration' | locale: { expiration: coupon?.expiresAt | localDate: 'medium' } }}
        </div>
      }
      @case ('expired') {
        <div class="coupon-expired">{{ 'components.couponItem.expiredMessage' | locale }}</div>
      }
      @case ('used') {
        @if (coupon?.kind === 'drawing') {
          <div class="coupon-date used">
            {{ 'components.couponItem.whenUsed' | locale: { date: coupon?.usedAt | localDate: 'medium' } }}
          </div>
        } @else {
          <div class="coupon-date">
            {{ 'components.couponItem.expiration' | locale: { expiration: coupon?.expiresAt | localDate: 'medium' } }}
          </div>
        }
      }
    }
  }
</div>
