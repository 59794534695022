<div class="start"><ng-content></ng-content></div>
<div class="end">
  @switch (type()) {
    @case ('arrow') {
      <sl-icon class="arrow" name="arrow"></sl-icon>
    }
    @case ('icon') {
      <sl-icon class="icon" [name]="icon"></sl-icon>
    }
    @default {
      <ng-content select="[slot=end]"></ng-content>
    }
  }
</div>
