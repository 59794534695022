<ng-container *slLoading="!loading">
  <div class="coupon-provider">
    <a class="coupon-provider-link" (click)="provideCouponCode()">
      <sl-icon class="icon" name="coupon"></sl-icon>
      <div class="text">{{ 'pages.mypageCouponConsumable.inputSerialCode' | locale }}</div>
    </a>
  </div>
  @if (coupons$ | async; as coupons) {
    @if (coupons?.length) {
      <sl-layout size="small" class="content" infiniteScroll (scrolled)="onScroll()">
        <div class="coupons">
          @for (coupon of coupons; track coupon.id) {
            <sl-coupon-item [coupon]="coupon" (click)="showCouponItemModal(coupon?.id)"></sl-coupon-item>
          }
        </div>
      </sl-layout>
    } @else {
      <sl-empty>{{ 'pages.mypageCouponConsumable.emptyMessage' | locale }}</sl-empty>
    }
  }
</ng-container>
