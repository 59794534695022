<sl-breadcrumb>
  <sl-breadcrumb-item path="/mypage">{{ 'shared.breadcrumbs.mypage' | locale }}</sl-breadcrumb-item>
  <sl-breadcrumb-item>{{ 'shared.breadcrumbs.paymentSettings' | locale }}</sl-breadcrumb-item>
</sl-breadcrumb>

<sl-page-header>{{ 'shared.breadcrumbs.paymentSettings' | locale }}</sl-page-header>

<sl-layout size="small" class="content">
  <ng-container *slLoading="!loading">
    @if (card$ | async; as card) {
      <sl-list>
        <sl-list-header>{{ 'pages.mypagePayment.cardNumber' | locale }}</sl-list-header>
        <sl-list-item>
          <sl-card-number [card]="card"></sl-card-number>
          <sl-card-logo slot="end" [brand]="card?.brand" class="card-logo"></sl-card-logo>
        </sl-list-item>
      </sl-list>
      @if (card?.name) {
        <sl-list>
          <sl-list-header>{{ 'pages.mypagePayment.cardHolderName' | locale }}</sl-list-header>
          <sl-list-item>{{ card.name }}</sl-list-item>
        </sl-list>
      }
      <sl-list>
        <sl-list-header>{{ 'pages.mypagePayment.cardExpired' | locale }}</sl-list-header>
        <sl-list-item>{{ card?.expMonth }}/{{ card?.expYear }}</sl-list-item>
      </sl-list>
      <div class="buttons">
        <sl-button (click)="showCardEditModal()">{{ 'pages.mypagePayment.editCard' | locale }}</sl-button>
        <sl-button color="warn" (click)="unsetCardHandler()">{{ 'pages.mypagePayment.deleteCard' | locale }}</sl-button>
      </div>
    } @else {
      <div class="no-card">
        <div class="no-card-text">{{ 'pages.mypagePayment.noCardMessage' | locale }}</div>
        <sl-button class="no-card-button" (click)="showCardEditModal()">{{
          'pages.mypagePayment.registerCard' | locale
        }}</sl-button>
      </div>
      <div class="no-card prepaid-card">
        <div class="no-card-text" [innerHTML]="'pages.mypagePayment.prepaidCardMessage' | locale | safeHtml"></div>
        <a slExternalLink="https://vandle.jp/">
          <sl-button class="no-card-button" type="stroked">{{
            'pages.mypagePayment.createPrepaidCard' | locale
          }}</sl-button>
        </a>
      </div>
    }
  </ng-container>

  <div class="back">
    <sl-button type="stroked" slBackButton defaultHref="/mypage">{{ 'shared.navigation.back' | locale }}</sl-button>
  </div>
</sl-layout>
