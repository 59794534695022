import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { Observable } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryCheckoutComponent } from '../../../components/lottery/lottery-checkout/lottery-checkout.component';
import { LotteryDigitalContentComponent } from '../../../components/lottery/lottery-digital-content/lottery-digital-content.component';
import { LotteryPrizeComponent } from '../../../components/lottery/lottery-prize/lottery-prize.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryCheckOut, LotteryLoot, LotteryMachine } from '../../../models/lottery';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalCurrencyPipe } from '../../../pipes/local-currency.pipe';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { PaymentMethodNamePipe } from '../../../pipes/payment-method-name.pipe';
import { SecretImagePipe } from '../../../pipes/secret-image.pipe';
import { LotteryCheckoutService } from '../../../services/lottery-checkout.service';
import { LotteryMachineService } from '../../../services/lottery-machine.service';

@Component({
  selector: 'page-mypage-history-detail',
  templateUrl: './mypage-history-detail.page.html',
  styleUrls: ['./mypage-history-detail.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    LotteryCheckoutComponent,
    ButtonComponent,
    RouterLink,
    PhotoGalleryModule,
    LotteryPrizeComponent,
    LotteryDigitalContentComponent,
    BackButtonDirective,
    AsyncPipe,
    ImageSizePipe,
    LocalCurrencyPipe,
    LocalDatePipe,
    LocalePipe,
    PaymentMethodNamePipe,
    SecretImagePipe,
    SafeHtmlPipe,
  ],
})
export class MypageHistoryDetailPage implements OnInit {
  checkoutId: string;
  checkout$: Observable<LotteryCheckOut>;
  loading = true;

  private readonly route = inject(ActivatedRoute);
  private readonly lotteryCheckoutService = inject(LotteryCheckoutService);
  private readonly lotteryMachineService = inject(LotteryMachineService);

  constructor() {
    this.checkoutId = this.route.snapshot.paramMap.get('checkoutId');
    this.checkout$ = this.lotteryCheckoutService.selectCheckout(this.checkoutId);
  }

  ngOnInit() {
    this.loading = true;
    this.lotteryCheckoutService.fetchCheckout(this.checkoutId).subscribe(() => {
      this.loading = false;
    });
  }

  showShipment(checkout: LotteryCheckOut): boolean {
    if (checkout.status === 'refunded') {
      return false;
    }
    if (checkout.notExchangeable) {
      return false;
    }

    return this.getCheckoutShipmentStatus(checkout) !== 'expired';
  }

  isDigitalContentAvailable(machine: LotteryMachine): boolean {
    return this.lotteryMachineService.isDigitalContentAvailable(machine);
  }

  includesDigitalContent(loots: LotteryLoot[]): boolean {
    return loots?.map((loot) => loot.prize)?.some((prize) => prize?.hasSecretBody) ?? false;
  }

  getCheckoutShipmentStatus(checkout: LotteryCheckOut) {
    return this.lotteryCheckoutService.getCheckoutShipmentStatus(checkout);
  }

  getCouponName(checkout: LotteryCheckOut): string {
    return checkout.paymentType === 'coupon' ? checkout.paymentable.name : '';
  }
}
