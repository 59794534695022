<ng-container *slLoading="!loading">
  @if (checkout$ | async; as checkout) {
    <sl-breadcrumb>
      <sl-breadcrumb-item path="/mypage">{{ 'shared.breadcrumbs.mypage' | locale }}</sl-breadcrumb-item>
      <sl-breadcrumb-item path="/mypage/history">{{
        'shared.breadcrumbs.purchaseHistory' | locale
      }}</sl-breadcrumb-item>
      <sl-breadcrumb-item>{{ checkout?.checkOutNumber }}</sl-breadcrumb-item>
    </sl-breadcrumb>
    @if ({ digitalContent: isDigitalContentAvailable(checkout?.machine) }; as snapshot) {
      <sl-layout size="small" class="content">
        <sl-lottery-checkout class="checkout" [checkout]="checkout"></sl-lottery-checkout>
        @if (showShipment(checkout)) {
          <div class="shipment">
            @if (checkout?.shipment) {
              <sl-button routerLink="/exchange/history/{{ checkout.shipment.id }}">{{
                'pages.mypageHistoryDetail.viewShippingInfo' | locale
              }}</sl-button>
            } @else {
              <div class="shipment-container">
                <div
                  class="shipment-message"
                  [innerHTML]="
                    'pages.mypageHistoryDetail.exchangeExpirationMessage'
                      | locale: { exchangeExpiration: checkout?.exchangeDueAt | localDate: 'medium' }
                      | safeHtml
                  "
                ></div>
                <sl-button routerLink="/exchange/top" class="shipment-request">{{
                  'pages.mypageHistoryDetail.requestShipping' | locale
                }}</sl-button>
              </div>
            }
          </div>
        }
        <div class="info">
          <div class="info-item">
            <div class="info-item-heading">{{ 'pages.mypageHistoryDetail.checkoutNumberLabel' | locale }}</div>
            <div class="info-item-body">{{ checkout?.checkOutNumber }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-heading">{{ 'pages.mypageHistoryDetail.checkoutDateLabel' | locale }}</div>
            <div class="info-item-body">{{ checkout?.createdAt | localDate: 'medium' }}</div>
          </div>
          @if (checkout?.paymentType === 'coupon') {
            <div class="info-item">
              <div class="info-item-heading">{{ 'pages.mypageHistoryDetail.useCouponLabel' | locale }}</div>
              <div class="info-item-body">{{ getCouponName(checkout) }}</div>
            </div>
          } @else {
            <div class="info-item">
              <div class="info-item-heading">{{ 'pages.mypageHistoryDetail.checkoutAmount' | locale }}</div>
              <div class="info-item-body">{{ checkout?.amount | localCurrency: { showTaxInfo: true } }}</div>
            </div>
            <div class="info-item">
              <div class="info-item-heading">{{ 'pages.mypageHistoryDetail.purchaseMethodNameLabel' | locale }}</div>
              <div class="info-item-body">{{ checkout?.purchaseMethodName }}</div>
            </div>
            @if (checkout?.amount > 0 && checkout?.paymentable?.type !== 'gacha_coupon') {
              <div class="info-item">
                <div class="info-item-heading">
                  {{ 'pages.mypageHistoryDetail.checkoutPaymentMethodLabel' | locale }}
                </div>
                <div class="info-item-body">{{ checkout?.paymentable | paymentMethodName }}</div>
              </div>
            }
          }
        </div>
        <div class="prizes" [photoGalleryGroup]="{ showHideOpacity: true, captionEl: true }">
          @for (loot of checkout?.loots; track loot.id) {
            <div class="prizes-item">
              <sl-lottery-prize
                class="prizes-item-body"
                [prize]="loot?.prize"
                [quantity]="loot?.quantity"
                [photoGallery]="loot?.prize | secretImage | imageSize: 'original'"
                [photoGalleryCaption]="loot?.prize?.name"
              ></sl-lottery-prize>
              <sl-lottery-digital-content
                [prize]="loot?.prize"
                [available]="snapshot?.digitalContent"
                [acquired]="true"
              ></sl-lottery-digital-content>
            </div>
          }
          @for (loot of checkout?.bonusLoots; track loot.bonusPrize.id) {
            <div class="prizes-item">
              <sl-lottery-prize
                class="prizes-item-body"
                [prize]="loot?.bonusPrize"
                [quantity]="loot?.quantity"
                [photoGallery]="loot?.bonusPrize | secretImage | imageSize: 'original'"
                [photoGalleryCaption]="loot?.bonusPrize?.name"
              ></sl-lottery-prize>
              <sl-lottery-digital-content
                [prize]="loot?.bonusPrize"
                [available]="snapshot?.digitalContent"
                [acquired]="true"
              ></sl-lottery-digital-content>
            </div>
          }
        </div>
        @if (includesDigitalContent(checkout?.loots)) {
          <div
            class="digital-content-due"
            [innerHTML]="
              'pages.mypageHistoryDetail.digitalContentExpirationMessage'
                | locale
                  : {
                      name: 'common.digitalContentsFull' | locale,
                      expiration: checkout?.machine?.digitalContentEndsAt | localDate: 'full',
                    }
                | safeHtml
            "
          ></div>
        }
        <div class="back">
          <sl-button type="stroked" slBackButton defaultHref="/mypage/history">{{
            'shared.navigation.back' | locale
          }}</sl-button>
        </div>
      </sl-layout>
    }
  }
</ng-container>
