<sl-breadcrumb>
  <sl-breadcrumb-item path="/mypage">{{ 'shared.breadcrumbs.mypage' | locale }}</sl-breadcrumb-item>
  <sl-breadcrumb-item>{{ 'shared.breadcrumbs.coupon' | locale }}</sl-breadcrumb-item>
</sl-breadcrumb>

<sl-page-header>{{ 'shared.breadcrumbs.coupon' | locale }}</sl-page-header>

<sl-layout size="small" class="nav">
  <sl-navbar [(ngModel)]="currentTab">
    <sl-navbar-item value="consumable" routerLink="consumable">{{
      'pages.mypageCoupon.consumable' | locale
    }}</sl-navbar-item>
    <sl-navbar-item value="non_consumable" routerLink="non-consumable">{{
      'pages.mypageCoupon.nonConsumable' | locale
    }}</sl-navbar-item>
    <sl-navbar-item value="expired" routerLink="expired">{{ 'pages.mypageCoupon.expired' | locale }}</sl-navbar-item>
  </sl-navbar>
</sl-layout>

<div class="content">
  <router-outlet></router-outlet>
</div>
