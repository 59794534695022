<sl-breadcrumb>
  <sl-breadcrumb-item path="/mypage">{{ 'shared.breadcrumbs.mypage' | locale }}</sl-breadcrumb-item>
  <sl-breadcrumb-item>{{ 'shared.breadcrumbs.accountSettings' | locale }}</sl-breadcrumb-item>
</sl-breadcrumb>

<sl-page-header>{{ 'shared.breadcrumbs.accountSettings' | locale }}</sl-page-header>

<sl-layout size="small" class="content">
  <ng-container *slLoading="!loading">
    @if (state$ | async; as state) {
      @if (state?.user?.dateOfBirth) {
        <sl-list>
          <sl-list-header>{{ 'pages.mypageAccount.birthDateHeader' | locale }}</sl-list-header>
          <sl-list-item>{{ state?.user?.dateOfBirth | localDate: 'mediumDate' }}</sl-list-item>
        </sl-list>

        @if (enablePhoneSignIn) {
          <sl-list>
            <sl-list-header>{{ 'pages.mypageAccount.phoneNumberHeader' | locale }}</sl-list-header>
            @if (state?.firebaseUser?.phoneNumber) {
              <sl-list-item>{{ state?.firebaseUser?.phoneNumber | phoneNumber }}</sl-list-item>
            } @else {
              <sl-list-item>{{ 'pages.mypageAccount.phoneNumberNotSet' | locale }}</sl-list-item>
            }
          </sl-list>

          <div class="buttons">
            <sl-button (click)="updatePhoneNumber()">{{
              (state?.firebaseUser?.phoneNumber
                ? 'pages.mypageAccount.changePhoneNumber'
                : 'pages.mypageAccount.setPhoneNumber'
              ) | locale
            }}</sl-button>
          </div>
        }

        @if (enableEmailSignIn || enableThirdPartySignIn) {
          <!-- メールログイン若しくはサードパーティログインが有効な場合のみメールアドレスセクションを表示する -->
          @if (enableEmailSignIn) {
            <sl-list>
              <sl-list-header>{{ 'pages.mypageAccount.emailAuthHeader' | locale }}</sl-list-header>
              @if (state?.firebaseUser?.email) {
                <sl-list-item>
                  <div class="email">
                    <span class="email-text">{{ state?.firebaseUser?.email }}</span>
                    @if (!state?.firebaseUser.emailVerified) {
                      <div class="email-verify">
                        <div class="email-status">{{ 'pages.mypageAccount.notVerifiedEmail' | locale }}</div>
                        @switch (sendVerificationEmailStatus) {
                          @case ('sent') {
                            <div class="email-action" [ngClass]="sendVerificationEmailStatus">
                              {{ 'pages.mypageAccount.sentVerificationEmail' | locale }}
                            </div>
                          }
                          @case ('unsent') {
                            <button
                              class="email-action"
                              (click)="resendVerificationEmail()"
                              [ngClass]="sendVerificationEmailStatus"
                            >
                              {{ 'pages.mypageAccount.resendVerificationEmail' | locale }}
                            </button>
                          }
                          @case ('sending') {
                            <mat-spinner class="email-sending" [diameter]="20"></mat-spinner>
                          }
                        }
                      </div>
                    }
                  </div>
                </sl-list-item>
              } @else {
                <sl-list-item>{{ 'pages.mypageAccount.emailNotSet' | locale }}</sl-list-item>
              }
              @if (enableNewsletter) {
                <ng-container [ngTemplateOutlet]="Newsletter"></ng-container>
              }
            </sl-list>
            <div class="buttons">
              @if (state?.firebaseUser?.email) {
                <sl-button (click)="changeEmail()">{{ 'pages.mypageAccount.changeEmail' | locale }}</sl-button>
              } @else {
                <sl-button (click)="registerEmail()">{{ 'pages.mypageAccount.registerEmail' | locale }}</sl-button>
              }
            </div>
          } @else {
            <sl-list>
              <sl-list-header>{{ 'pages.mypageAccount.emailHeader' | locale }}</sl-list-header>
              @if (state?.firebaseUser?.email) {
                <sl-list-item>
                  <div class="email">
                    <span class="email-text">{{ state?.firebaseUser?.email }}</span>
                  </div>
                </sl-list-item>
              }
              @if (enableNewsletter) {
                <ng-container [ngTemplateOutlet]="Newsletter"></ng-container>
              }
            </sl-list>
          }

          <ng-template #Newsletter>
            <sl-list-item class="newsletter">
              <div class="newsletter-container">
                @if (state?.user?.newsletterSubscribed) {
                  <sl-icon class="newsletter-icon" name="check"></sl-icon>
                  <div class="newsletter-status">{{ 'pages.mypageAccount.newsletterStatusSubscribed' | locale }}</div>
                  <sl-button class="newsletter-button" color="warn" (click)="unsubscribeNewsletter()">{{
                    'pages.mypageAccount.buttonUnsubscribeCaption' | locale
                  }}</sl-button>
                } @else {
                  <div class="newsletter-status not-subscribed">
                    {{ 'pages.mypageAccount.newsletterStatusNotSubscribed' | locale }}
                  </div>
                  <sl-button class="newsletter-button" color="accent" (click)="subscribeNewsletter()">{{
                    'pages.mypageAccount.buttonSubscribeCaption' | locale
                  }}</sl-button>
                }
              </div>
            </sl-list-item>
          </ng-template>
        }

        @if (enableGoogleSignIn) {
          <sl-list>
            <sl-list-header>{{ 'pages.mypageAccount.linkWithGoogleHeader' | locale }}</sl-list-header>
            @if (oauthService.findProviderData(state.firebaseUser, 'google.com'); as googleProvider) {
              <sl-list-item>
                <div class="provider">
                  <div class="provider-inner">
                    <img src="slashkit-icons/google.svg" alt="Google" class="provider-icon" />
                    <div class="text">{{ googleProvider.email }}</div>
                  </div>
                  <sl-button class="provider-unlink" color="warn" (click)="unlink('google.com')">
                    {{ 'pages.mypageAccount.unlinkGoogleAccount' | locale }}
                  </sl-button>
                </div>
              </sl-list-item>
            } @else {
              <sl-list-item class="not-linked-item">
                {{ 'pages.mypageAccount.googleAccountUnlinked' | locale }}
                <sl-login-button
                  slot="end"
                  type="google"
                  text="{{ 'shared.account.linkWithGoogle' | locale }}"
                  (click)="linkWithGoogle()"
                ></sl-login-button>
              </sl-list-item>
            }
          </sl-list>
        }
        @if (enableAppleSignIn) {
          <sl-list class="slim">
            <sl-list-header>{{ 'pages.mypageAccount.linkWithAppleHeader' | locale }}</sl-list-header>
            @if (oauthService.findProviderData(state.firebaseUser, 'apple.com'); as appleProvider) {
              <sl-list-item>
                <div class="provider">
                  <div class="provider-inner">
                    <img src="slashkit-icons/apple-black.svg" alt="Apple" class="provider-icon" />
                    <div class="text">{{ appleProvider.email }}</div>
                  </div>
                  <sl-button class="provider-unlink" color="warn" (click)="unlink('apple.com')">
                    {{ 'pages.mypageAccount.unlinkAppleAccount' | locale }}
                  </sl-button>
                </div>
              </sl-list-item>
            } @else {
              <sl-list-item class="not-linked-item">
                {{ 'pages.mypageAccount.appleAccountUnlinked' | locale }}
                <sl-login-button
                  slot="end"
                  type="apple"
                  text="{{ 'shared.account.linkWithApple' | locale }}"
                  (click)="linkWithApple()"
                ></sl-login-button>
              </sl-list-item>
            }
          </sl-list>
        }
        @if (enableThirdPartySignIn) {
          <div class="description">
            {{ 'pages.mypageAccount.linkAccountDescription' | locale }}
          </div>
        }
      } @else {
        @if (!state.firebaseUser.isAnonymous) {
          <div class="no-data">
            <div class="no-data-text">{{ 'pages.mypageAccount.invalidBirthDateMessage' | locale }}</div>
            <sl-button class="no-data-button" (click)="showDateOfBirthModal()">{{
              'pages.mypageAccount.registerBirthDate' | locale
            }}</sl-button>
          </div>
        }
      }
    }
  </ng-container>
  <div class="back">
    <sl-button type="stroked" slBackButton defaultHref="/mypage">{{ 'shared.navigation.back' | locale }}</sl-button>
  </div>
</sl-layout>
