import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryCheckoutComponent } from '../../../components/lottery/lottery-checkout/lottery-checkout.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryCheckOut } from '../../../models/lottery';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LotteryCheckoutService } from '../../../services/lottery-checkout.service';

@Component({
  selector: 'page-mypage-history',
  templateUrl: './mypage-history.page.html',
  styleUrls: ['./mypage-history.page.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LoadingDirective,
    LayoutComponent,
    InfiniteScrollDirective,
    LotteryCheckoutComponent,
    RouterLink,
    EmptyComponent,
    AsyncPipe,
    LocalePipe,
  ],
})
export class MypageHistoryPage implements OnInit {
  checkouts$: Observable<LotteryCheckOut[]>;
  loading = true;
  loadingMore = false;

  private readonly lotteryCheckoutService = inject(LotteryCheckoutService);

  constructor() {
    this.checkouts$ = this.lotteryCheckoutService.checkouts$;
  }

  ngOnInit() {
    this.loading = true;
    this.lotteryCheckoutService.fetchCheckouts(true).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryCheckoutService.checkoutPage.isComplete) {
      this.loadingMore = true;
      this.lotteryCheckoutService.fetchCheckouts().subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
