import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { Observable } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { CardLogoComponent } from '../../../components/card-logo/card-logo.component';
import { CardNumberComponent } from '../../../components/card-number/card-number.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { ListHeaderComponent } from '../../../components/list/list-header/list-header.component';
import { ListItemComponent } from '../../../components/list/list-item/list-item.component';
import { ListComponent } from '../../../components/list/list/list.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { ExternalLinkDirective } from '../../../directives/external-link.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Card } from '../../../models/payment';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { AlertService } from '../../../services/alert.service';
import { LocaleService } from '../../../services/locale.service';
import { PaymentService } from '../../../services/payment.service';
import { UserDetailService } from '../../../services/user-detail.service';

@Component({
  selector: 'page-mypage-payment',
  templateUrl: './mypage-payment.page.html',
  styleUrls: ['./mypage-payment.page.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    LoadingDirective,
    ListComponent,
    ListHeaderComponent,
    ListItemComponent,
    CardNumberComponent,
    CardLogoComponent,
    ButtonComponent,
    ExternalLinkDirective,
    BackButtonDirective,
    AsyncPipe,
    LocalePipe,
    SafeHtmlPipe,
  ],
})
export class MypagePaymentPage implements OnInit {
  card$: Observable<Card>;
  loading = true;

  private readonly alertService = inject(AlertService);
  private readonly paymentService = inject(PaymentService);
  private readonly userDetailService = inject(UserDetailService);
  private readonly localeService = inject(LocaleService);

  constructor() {
    this.card$ = this.paymentService.card$;
  }

  ngOnInit() {
    this.loading = true;
    this.paymentService.fetchCard().subscribe(() => {
      this.loading = false;
    });
  }

  async showCardEditModal() {
    await this.userDetailService.showPaymentEditModal();
  }

  async unsetCardHandler() {
    const confirm = await this.alertService.showConfirmation({
      header: this.localeService.translate('pages.mypagePayment.deleteCardAlertHeader'),
      message: this.localeService.translate('pages.mypagePayment.deleteCardAlertMessage'),
      buttonText: this.localeService.translate('pages.mypagePayment.deleteCardAlertButtonText'),
      buttonColor: 'warn',
    });
    if (confirm) {
      this.unsetCard();
    }
  }

  private async unsetCard() {
    await this.alertService.showLoader(this.localeService.translate('pages.mypagePayment.deleteCardLoadingMessage'));
    this.paymentService.unsetCard().subscribe(() => {
      this.alertService.dismissLoader();
      this.alertService.showToast(this.localeService.translate('pages.mypagePayment.deleteCardSuccessMessage'));
    });
  }
}
