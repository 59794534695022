import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { NavbarItemComponent } from '../../../components/navbar/navbar-item/navbar-item.component';
import { NavbarComponent } from '../../../components/navbar/navbar/navbar.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { LotteryCouponType } from '../../../models/lottery-coupon';
import { LocalePipe } from '../../../pipes/locale.pipe';

@Component({
  selector: 'page-mypage-coupon',
  templateUrl: './mypage-coupon.page.html',
  styleUrls: ['./mypage-coupon.page.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    NavbarComponent,
    FormsModule,
    NavbarItemComponent,
    RouterLink,
    RouterOutlet,
    LocalePipe,
  ],
})
export class MypageCouponPage implements OnInit {
  currentTab: LotteryCouponType = 'consumable';
  loading = true;

  constructor() {}

  ngOnInit() {}

  async setCurrentTab(tab: LotteryCouponType) {
    // ExpressionChangedAfterItHasBeenCheckedError回避の為に1サイクル待ってからプロパティを書き換える
    await Promise.resolve();
    this.currentTab = tab;
  }
}
