import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { User as FirebaseUser } from '@angular/fire/auth';
import { RouterLink } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { IconComponent } from '../../../components/icon/icon.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { ListHeaderComponent } from '../../../components/list/list-header/list-header.component';
import { ListItemComponent } from '../../../components/list/list-item/list-item.component';
import { ListComponent } from '../../../components/list/list/list.component';
import { LoginButtonsComponent } from '../../../components/login-buttons/login-buttons.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { LinkDirective } from '../../../directives/link.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { SlashkitAssets } from '../../../models/config';
import { Card } from '../../../models/payment';
import { User } from '../../../models/user';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { AlertService } from '../../../services/alert.service';
import { SessionService } from '../../../services/auth/session.service';
import { LocaleService } from '../../../services/locale.service';
import { PaymentService } from '../../../services/payment.service';
import { UserService } from '../../../services/user.service';
import { combineStream } from '../../../util';

export type MypageRootState = {
  firebaseUser: FirebaseUser;
  user: User;
  card?: Card;
};

@Component({
  selector: 'page-mypage-root',
  templateUrl: './mypage-root.page.html',
  styleUrls: ['./mypage-root.page.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    LoadingDirective,
    LayoutComponent,
    ListComponent,
    ListHeaderComponent,
    ListItemComponent,
    RouterLink,
    IconComponent,
    LoginButtonsComponent,
    LinkDirective,
    AsyncPipe,
    LocalePipe,
  ],
})
export class MypageRootPage implements OnInit {
  readonly assets = inject(SlashkitAssets);

  readonly state$: Observable<MypageRootState>;
  navLinks = this.assets.navLinks;
  loading = true;

  private readonly sessionService = inject(SessionService);
  private readonly userService = inject(UserService);
  private readonly paymentService = inject(PaymentService);
  private readonly alertService = inject(AlertService);
  private readonly localeService = inject(LocaleService);

  constructor() {
    this.state$ = combineStream({
      firebaseUser: this.sessionService.firebaseUser$,
      user: this.userService.currentUser$,
      card: this.paymentService.card$,
    });
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([this.userService.fetchUserDetail(), this.paymentService.fetchCard()]).subscribe(() => {
      this.loading = false;
    });
  }

  async signOut() {
    const confirm = await this.alertService.showConfirmation({
      header: this.localeService.translate('shared.account.signOutAlertHeader'),
      message: this.localeService.translate('shared.account.signOutAlertMessage'),
      buttonText: this.localeService.translate('shared.account.signOutAlertButtonText'),
      buttonColor: 'warn',
    });
    if (confirm) {
      await this.sessionService.signOut();
    }
  }

  signUp() {
    this.sessionService.signUp();
  }
}
