import { Observable } from 'rxjs';

import { LotteryCoupon, LotteryCouponType } from '../../../models/lottery-coupon';
import { LotteryCouponService } from '../../../services/lottery-coupon.service';

import { MypageCouponPage } from './mypage-coupon.page';

export abstract class CouponPage {
  coupons$: Observable<LotteryCoupon[]>;
  loading = true;
  loadingMore = false;
  abstract readonly status: LotteryCouponType;

  constructor(
    protected mypageCouponPage: MypageCouponPage,
    protected lotteryCouponService: LotteryCouponService,
  ) {}

  onInit() {
    this.loading = true;
    this.lotteryCouponService.fetchCoupons(this.status, true).subscribe(() => {
      this.loading = false;
    });
    if (this.mypageCouponPage) {
      this.mypageCouponPage.setCurrentTab(this.status);
    }
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryCouponService.couponPage[this.status].isComplete) {
      this.loadingMore = true;
      this.lotteryCouponService.fetchCoupons(this.status).subscribe(() => {
        this.loadingMore = false;
      });
    }
  }

  provideCouponCode() {
    this.lotteryCouponService.showCouponCodeModal().subscribe((coupon) => {
      if (coupon) {
        this.showCouponItemModal(coupon.id);
      }
      this.loading = true;
      this.lotteryCouponService.fetchCoupons(this.status, true).subscribe(() => {
        this.loading = false;
      });
    });
  }

  showCouponItemModal(couponId: string) {
    this.lotteryCouponService.showCouponItemModal(couponId);
  }
}
