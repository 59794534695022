import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ListComponent {
  constructor() {}
}
