import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Card } from '../../models/payment';

@Component({
  selector: 'sl-card-number',
  templateUrl: './card-number.component.html',
  styleUrls: ['./card-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CardNumberComponent implements OnInit {
  @Input() card: Card;

  constructor() {}

  ngOnInit(): void {}
}
