import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

import { CouponItemComponent } from '../../../components/coupon/coupon-item/coupon-item.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryCouponType } from '../../../models/lottery-coupon';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LotteryCouponService } from '../../../services/lottery-coupon.service';
import { CouponPage } from '../mypage-coupon/coupon-page-abstract';
import { MypageCouponPage } from '../mypage-coupon/mypage-coupon.page';

@Component({
  selector: 'page-mypage-coupon-non-consumable',
  templateUrl: './mypage-coupon-non-consumable.page.html',
  styleUrls: ['./mypage-coupon-non-consumable.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    IconComponent,
    LayoutComponent,
    InfiniteScrollDirective,
    CouponItemComponent,
    EmptyComponent,
    AsyncPipe,
    LocalePipe,
  ],
})
export class MypageCouponNonConsumablePage extends CouponPage implements OnInit {
  readonly status: LotteryCouponType = 'non_consumable';

  constructor() {
    const mypageCouponPage = inject(MypageCouponPage, { optional: true });
    const lotteryCouponService = inject(LotteryCouponService);

    super(mypageCouponPage, lotteryCouponService);
    this.coupons$ = this.lotteryCouponService.selectCoupons(this.status);
  }

  ngOnInit() {
    super.onInit();
  }
}
